import React, { useEffect } from 'react';
import { CardType, CarouselDisplayStyle, } from '@honeycomb/data';
import { Carousel as CoreCarousel, CarouselItem, CarouselDots, Styler, LazyLoadComponent, CarouselViewport, useBreakpoints, useDisclosure, } from '@honeycomb/ui-core';
import { pushPromotedDeals } from '@honeycomb/tracking';
import dynamic from 'next/dynamic';
import { useRootComponent } from '../RootComponentContext';
import { DealCard } from '../DealCard/DealCard';
import { PriceBlockCard } from '../PriceBlockCard/PriceBlockCard';
import { MerchandiseCard } from '../MerchandiseCard';
const CAROUSEL_THEME = {
    visibleItems: {
        Default: { xs: 1.1, s: 1.5, m: 1.8, l: 3 },
        FullWidth: 1,
        Adjacent: { xs: 1.1, s: 1.25, m: 1.8, l: 1.25, xl: 1.5 },
    },
    spacing: {
        Default: { xs: 4, m: 5, l: 6 },
        FullWidth: { xs: 4, m: 5, l: 10 },
        Adjacent: { xs: 5, m: 7 },
    },
};
const DestinationCardDialog = dynamic(() => import('../DestinationCardDialog').then((module) => module.DestinationCardDialog), { ssr: false });
const TripAdvisorReviewsDialog = dynamic(() => import('../../tripadvisor/TripAdvisorReviewsDialog').then((module) => module.TripAdvisorReviewsDialog), { ssr: false });
const HotelProductCard = dynamic(() => import('../HotelProductCard').then((module) => module.HotelProductCard), { ssr: false });
const DestinationCard = dynamic(() => import('../DestinationCard').then((module) => module.DestinationCard), { ssr: false });
const GenericCard = dynamic(() => import('../GenericCard').then((module) => module.GenericCard), {
    ssr: false,
});
export function Carousel(props) {
    const { carousel, tabIndex, moreLinkText, zoneIds } = props;
    const { slides } = carousel;
    const [dialogOpen, { open: handleOpenDialog, close: handleCloseDialog }] = useDisclosure(false);
    const [dialogExited, setDialogExited] = React.useState(true);
    const [selectedCard, setSelectedCard] = React.useState(null);
    const { carouselDisplayStyle, hasRepeatingBackgroundImage } = useRootComponent();
    const { largeUp } = useBreakpoints();
    const adjacentLayout = carouselDisplayStyle === CarouselDisplayStyle.Adjacent && largeUp;
    const fullWidth = carouselDisplayStyle === CarouselDisplayStyle.FullWidth;
    const [navElement, setNavElement] = React.useState(null);
    const handleOpenCardDialog = (card) => {
        setSelectedCard(card);
        setDialogExited(false);
        handleOpenDialog();
    };
    const hasDestinationCards = slides.some((slide) => slide.__typename === 'DestinationCard');
    const hasHotelCards = slides.some((slide) => slide.__typename === 'HotelCard');
    useEffect(() => {
        const dealCards = slides.filter((slide) => slide.__typename === 'DealCard');
        if (dealCards.length === 0)
            return;
        pushPromotedDeals(dealCards);
    }, [slides]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CoreCarousel, { visibleItems: CAROUSEL_THEME.visibleItems[carouselDisplayStyle], spacing: CAROUSEL_THEME.spacing[carouselDisplayStyle], overflow: fullWidth ? 'hidden' : 'visible', color: hasRepeatingBackgroundImage ? 'secondary' : 'primary' },
            React.createElement("div", null,
                React.createElement(CarouselViewport, null, slides.map((slide, index) => {
                    let { cardType } = slide;
                    if (slide.__typename === 'Card')
                        cardType = slide.type;
                    else if (slide.__typename === 'MerchandiseCard')
                        cardType = CardType.Merchandise;
                    const key = `${slide.__typename}-slide-${index.toString()}`;
                    let card;
                    switch (cardType) {
                        case CardType.Hotel: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(HotelProductCard, { onReviewOpen: handleOpenCardDialog, card: slide }) }));
                            break;
                        }
                        case CardType.Destination: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(DestinationCard, { card: slide, onShowMore: handleOpenCardDialog, index: index.toString(), typename: slide === null || slide === void 0 ? void 0 : slide.__typename, tabIndex: tabIndex }) }));
                            break;
                        }
                        case CardType.Deal:
                            card = React.createElement(DealCard, { card: slide });
                            break;
                        case CardType.Priceblock:
                            card = React.createElement(PriceBlockCard, { cards: slide });
                            break;
                        case CardType.Merchandise:
                            card = (React.createElement(MerchandiseCard, { card: slide, moreLinkText: moreLinkText, key: key, carouselDisplayStyle: carouselDisplayStyle, zoneIds: zoneIds }));
                            break;
                        default: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(GenericCard, { card: slide }) }));
                        }
                    }
                    return React.createElement(CarouselItem, { key: key }, card);
                })),
                React.createElement(Styler, Object.assign({ display: "flex", alignItems: "center", justifyContent: "center", pt: navElement ? { xs: 6, l: 8 } : undefined }, (adjacentLayout
                    ? {
                        pos: 'absolute',
                        left: 0,
                        bottom: 0,
                        zIndex: 2,
                        'data-content-margins': true,
                        pt: 0,
                    }
                    : null)),
                    React.createElement(CarouselDots, { ref: (ref) => setNavElement(ref) })))),
        (dialogOpen || !dialogExited) && (React.createElement(React.Fragment, null,
            hasHotelCards && (selectedCard === null || selectedCard === void 0 ? void 0 : selectedCard.__typename) === 'HotelCard' && (React.createElement(TripAdvisorReviewsDialog, { open: dialogOpen, tripadvisorFeedback: selectedCard.hotel.tripadvisorFeedback, hotelName: selectedCard.hotel.name, onClose: handleCloseDialog, onExited: () => setDialogExited(true) })),
            hasDestinationCards && (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(DestinationCardDialog, { card: selectedCard, open: dialogOpen, onClose: handleCloseDialog, onExited: () => setDialogExited(true) }) }))))));
}
