import { CarouselDisplayStyle, } from '@honeycomb/data';
import { BackgroundImage, Container, FlexGrid, Styler, useHoneycombTheme } from '@honeycomb/ui-core';
import React, { useMemo } from 'react';
import { GenericSubComponent } from './GenericSubComponent';
import { RootComponentContext } from './RootComponentContext';
import { getBackgroundColor } from './utils/getBackgroundColor';
import { DEFAULT_COMPONENT_PADDING } from './utils/constants';
import { getWidgetBackgroundProps } from './utils/getWidgetBackgroundProps';
export function GenericRootComponent(props) {
    const { component, moreLinkText, zoneIds } = props;
    const { carouselDisplayStyle, backgrounds } = component;
    const backgroundColor = backgrounds === null || backgrounds === void 0 ? void 0 : backgrounds.find((background) => (background === null || background === void 0 ? void 0 : background.__typename) === 'BackgroundColour');
    const widgetBackgrounds = backgrounds;
    const { theme } = useHoneycombTheme();
    const backgroundProps = useMemo(() => {
        return widgetBackgrounds && widgetBackgrounds.length > 0
            ? getWidgetBackgroundProps(widgetBackgrounds)
            : null;
    }, [widgetBackgrounds]);
    const contextValues = useMemo(() => {
        var _a, _b;
        const hasRepeatingBackgroundImage = (_a = (backgroundProps &&
            Boolean(backgroundProps.backgroundImage && backgroundProps.backgroundRepeat === 'repeat'))) !== null && _a !== void 0 ? _a : false;
        return {
            backgroundColor: (_b = backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.name) !== null && _b !== void 0 ? _b : undefined,
            carouselDisplayStyle: carouselDisplayStyle !== null && carouselDisplayStyle !== void 0 ? carouselDisplayStyle : CarouselDisplayStyle.Default,
            hasRepeatingBackgroundImage,
        };
    }, [backgroundColor, backgroundProps, carouselDisplayStyle]);
    const bgColor = getBackgroundColor(backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.name);
    const adjacentPanelColor = theme.fn.resolveColorValue(bgColor !== null && bgColor !== void 0 ? bgColor : 'background.body');
    const adjacentLayout = component.carouselDisplayStyle === CarouselDisplayStyle.Adjacent;
    const subComponents = component.content.map((childComponent, index) => {
        const key = `${component.contentId}-${index.toString()}`;
        const subComponent = (React.createElement(GenericSubComponent, { component: childComponent, key: key, alias: component === null || component === void 0 ? void 0 : component.alias, moreLinkText: moreLinkText, zoneIds: zoneIds }));
        if (adjacentLayout) {
            return (
            // Add a grid cell for the adjacent content.
            React.createElement(FlexGrid, { xs: 12, l: 6 }, subComponent));
        }
        return subComponent;
    });
    return (React.createElement(RootComponentContext.Provider, { value: contextValues },
        React.createElement(Styler, { component: "section", "data-id": `section-${component === null || component === void 0 ? void 0 : component.alias}-generic-component`, bg: bgColor, pos: "relative", overflow: "hidden" // Required for Carousel overflow
         },
            adjacentLayout && (
            // This opaque panel is used to mask carousel overflow
            React.createElement(Styler, { pos: "absolute", top: "0", left: "0", w: "50%", h: "100%", zIndex: 1, display: { xs: 'none', l: 'block' }, bg: `linear-gradient(to left, transparent, ${adjacentPanelColor} 120px)`, baseStyles: {
                    backdropFilter: 'blur(12px)',
                } })),
            React.createElement(BackgroundImage, Object.assign({ component: "div" }, backgroundProps, { py: DEFAULT_COMPONENT_PADDING }),
                React.createElement(Container, null, adjacentLayout ? (
                // Add a grid container for the adjacent content.
                React.createElement(FlexGrid, { container: true, "data-adjacent-layout-grid": true }, subComponents)) : (subComponents))))));
}
