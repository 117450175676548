import { BackgroundColourName } from '@honeycomb/data';
export function getBodyColor(bgColor) {
    switch (bgColor) {
        case BackgroundColourName.Primary:
        case BackgroundColourName.Accent:
        case BackgroundColourName.Secondary:
            return 'common.white';
        default:
            return 'text.body';
    }
}
